import './Button.scss';
import { Button as AntdButton } from 'antd';

interface IButton {
  text: string;
  className?: string;
  onClick: () => void;
}

const Button = ({ text, className, onClick }: IButton) => {
  return (
    <AntdButton
      className={`lets-talk-button ${className}`}
      type='primary'
      onClick={onClick}
    >
      {text}
    </AntdButton>
  );
};

export default Button;
