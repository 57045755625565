import './NavigationBar.scss';
import { Dropdown, MenuProps } from 'antd';
import { IoChevronForwardSharp, IoChevronBack } from 'react-icons/io5';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logo } from 'assets';
import { useState } from 'react';
import appRoutes from 'routes';
import TabListItem from './TabListItem/TabListItem';
import { UseWindowSize } from 'common/hooks/UseWindowSize';
import Button from 'common/components/Button/Button';
import { scrollToSection } from 'common/utils';

interface INavigationBar {
  isButtonInTheViewport: boolean;
}

const NavigationBar = ({ isButtonInTheViewport }: INavigationBar) => {
  /* UseState  */
  const [isOpen, setIsOpen] = useState(true);
  const [isProjectWasClick, setIsProjectWasClick] = useState(false);
  const [, screenWidth] = UseWindowSize();
  const navigate = useNavigate();
  let location = useLocation();

  /* Variables  */
  let classes = isOpen ? 'close' : 'open';
  const screenLimit = 700;
  const projects = {
    ninjaMarketing: 'Ninjainfluence - Influencer Marketing',
    wishful: 'Wishful Market - eCommerce',
    ninjaPayment: 'Ninjainfluence - Payment System',
  };

  const handleItemClick = (e: any) => {
    switch (e.target.innerHTML) {
      case 'Project':
        return setIsProjectWasClick((pre) => !pre);

      case 'About me':
        if (location.pathname !== appRoutes.home) {
          navigate(appRoutes.home);
        }
        setIsOpen((pre) => !pre);
        setTimeout(() => {
          scrollToSection('about-title');
        }, 0);

        return;

      case 'Contact':
        setIsOpen((pre) => !pre);
        scrollToSection('contact-title');
        return;
    }
  };

  const getNavItems = (e: any) => (
    <>
      <div className='item' onClick={(e) => handleItemClick(e)}>
        <p>Project</p>
        <IoChevronForwardSharp className='right-arrow' />
      </div>
      <div className='item' onClick={(e) => handleItemClick(e)}>
        <p>About me</p>
        <IoChevronForwardSharp className='right-arrow hide-arrow' />
      </div>
      <div className='item' onClick={(e) => handleItemClick(e)}>
        <p>Contact</p>
        <IoChevronForwardSharp className='right-arrow hide-arrow' />
      </div>
    </>
  );

  const getProjectSubItems = () => (
    <div className='project-tab-wrapper'>
      <header
        className='project-tab-header'
        onClick={() => setIsProjectWasClick((pre) => !pre)}
      >
        <IoChevronBack className='left-arrow' />
        <p>Home</p>
      </header>

      <p className='tab-name'>Projects</p>

      <div className='tab-list-wrapper'>
        <TabListItem
          itemName={projects.ninjaMarketing}
          onClick={function (): void {
            setIsOpen((pre) => !pre);
            navigate(appRoutes.influencer);
          }}
        />
        <TabListItem
          itemName={projects.wishful}
          onClick={function (): void {
            setIsOpen((pre) => !pre);
            navigate(appRoutes.wishful);
          }}
        />
        <TabListItem
          itemName={projects.ninjaPayment}
          onClick={function (): void {
            setIsOpen((pre) => !pre);
            navigate(appRoutes.payment);
          }}
        />
      </div>
    </div>
  );

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    switch (e.domEvent.target.innerHTML) {
      case projects.ninjaMarketing:
        return navigate(appRoutes.influencer);
      case projects.wishful:
        return navigate(appRoutes.wishful);
      case projects.ninjaPayment:
        return navigate(appRoutes.payment);
      default:
        return navigate(appRoutes.home);
    }
  };

  const items: MenuProps['items'] = [
    {
      label: projects.ninjaMarketing,
      key: '1',
    },
    {
      label: projects.wishful,
      key: '2',
    },
    {
      label: projects.ninjaPayment,
      key: '3',
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      {/* Small screens */}
      {screenWidth < screenLimit ? (
        <div className='navigation-wrapper'>
          <div className='overlay'></div>
          <nav className={`${classes}`}>
            <Link to={appRoutes.home}>
              <img
                onClick={() => setIsOpen(true)}
                className='logo'
                src={logo}
                alt="Deluxe's logo"
              />
            </Link>
            <Button
              text="Let's Talk"
              onClick={() => {
                if (!isOpen) setIsOpen(true);
                scrollToSection('contact-title');
              }}
            />
            <button
              onClick={() => setIsOpen((pre) => !pre)}
              className='nav-icon-collapse'
              aria-label='navigation icon'
            >
              <svg
                width='50'
                height='50'
                viewBox='0 0 31 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  className={`middle-line ${classes}`}
                  d='M29 11H2'
                  stroke='black'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
                <path
                  className={`bottom-line ${classes}`}
                  d='M14 20.5H2'
                  stroke='black'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
                <path
                  className={`top-line ${classes}`}
                  d='M29 1.5H17'
                  stroke='black'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
              </svg>
            </button>
          </nav>

          {!isOpen ? (
            <div className='active-nav-items'>
              <div></div>
              {!isProjectWasClick
                ? getNavItems(handleItemClick)
                : getProjectSubItems()}
            </div>
          ) : (
            <div className='hidden-nav-items'></div>
          )}
        </div>
      ) : (
        <>
          {/* Bigger screens */}
          <div className='navigation-wrapper large-screen'>
            <div className='overlay'></div>
            <nav className={`${classes}`}>
              <Link to={appRoutes.home}>
                <img className='logo' src={logo} alt="Deluxe's logo" />
              </Link>
              {isButtonInTheViewport ? null : (
                <Button
                  text="Let's Talk"
                  className='get-in'
                  onClick={() => {
                    if (!isOpen) setIsOpen(true);
                    scrollToSection('contact-title');
                  }}
                />
              )}
              <div className='action-burtons'>
                <Dropdown menu={menuProps} className='nav-dropdown nav-item'>
                  <button className='project-button'>
                    Projects
                    <IoChevronForwardSharp />
                  </button>
                </Dropdown>
                <button
                  type='button'
                  className='nav-item'
                  onClick={() => {
                    if (location.pathname !== appRoutes.home) {
                      navigate(appRoutes.home);
                    }
                    setTimeout(() => {
                      scrollToSection('about-title');
                    }, 0);
                  }}
                >
                  About me
                </button>
                <button
                  type='button'
                  className='nav-item'
                  onClick={() => scrollToSection('contact-title')}
                >
                  Contact
                </button>
              </div>
            </nav>
          </div>
        </>
      )}
    </>
  );
};

export default NavigationBar;
