import './Card.scss';
import { Link, useNavigate } from 'react-router-dom';

interface ICard {
  text: string;
  image: string;
  url: string;
  classNameTag: string;
}

const Card = ({ text, image, url, classNameTag }: ICard) => {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate(url)} className='card-wrapper'>
      <div className='card-overlay'></div>
      <div className='left-side'>
        <p className='card-text'>{text}</p>
        <button>
          <Link className='card-link' to={url}>
            View project
          </Link>
        </button>
      </div>
      <img className={`card-image ${classNameTag}`} src={image} alt='' />
    </div>
  );
};

export default Card;
