import About from 'components/About/About';
import Hero from 'components/Hero/Hero';
import Projects from 'components/Projects/Projects';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Home = () => {
  const routePath = useLocation();
  useEffect(() => window.scrollTo(0, 0), [routePath]);
  return (
    <>
      <Hero />
      <Projects />
      <About />
    </>
  );
};

export default Home;
