import { useEffect, useState } from 'react'

export const UseWindowSize = (): number[] => {
  const [screenSize, setScreenSize] = useState([window.innerHeight, window.innerWidth]);

  useEffect((): () => void => {
    const handleWindowSizeChange = () => setScreenSize([window.innerHeight, window.innerWidth])
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, [])

  return screenSize;
}


export const UseWindowScroll = (): number => {
  const [scroll, setScroll] = useState(window.scrollY);

  useEffect((): () => void => {
    const handleScrollChange = () => setScroll(window.scrollY)
    window.addEventListener('scroll', handleScrollChange);
    return () => window.removeEventListener('scroll', handleScrollChange);
  }, [])

  return scroll;
}

