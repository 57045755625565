import './About.scss';
import { resume } from 'assets';
import { UseWindowSize } from 'common/hooks/UseWindowSize';

const About = () => {
  const [, screenWidth] = UseWindowSize();

  const screenLimit = 700;
  return (
    <div className='about-wrapper'>
      <div className='about-content-wrapper'>
        <div className='about-content-description'>
          <p className='about-title'>About me</p>
          <p className='about-description'>
            I'm Maor Knafo, a qualified and experienced UX/UI designer.
          </p>
          <p className='about-description'>
            My passion is User Experience design based on thorough,
            comprehensive research and an insightful understanding of users'
            needs.
          </p>
          <p className='about-description'>
            I love design, innovation, and the challenge of deciphering our
            thinking.
          </p>
          <p className='about-description'>
            I bring experience and practical knowledge to my projects in all
            stages of the design process.
          </p>
          <p className='about-description'>
            I manage and offer end-to-end products, including UX/UI processes,
            alongside the development function.
          </p>
          <a
            className='cv-link'
            target='_blank'
            href='https://drive.google.com/file/d/1oCqjdyRfoFvEr8cal-jPjelQngJVBxz7/view'
            rel='noreferrer'
          >
            Download my C.V
          </a>
        </div>
        {screenWidth > screenLimit ? (
          <img className='cv-image' src={resume} alt="Maor's resume" />
        ) : null}
      </div>
    </div>
  );
};

export default About;
