import './TabListItem.scss';
import { IoChevronForwardSharp } from 'react-icons/io5';

interface ITabListItem {
  itemName: string;
  onClick: () => void;
}

const TabListItem = ({ itemName, onClick }: ITabListItem) => {
  return (
    <div className='tab-list-item' onClick={onClick}>
      <p>{itemName}</p>
      <IoChevronForwardSharp />
    </div>
  );
};

export default TabListItem;
