export const scrollToSection = (elementToScroll: string) => {
  const element = document.getElementsByClassName(elementToScroll);
  if (element.length > 0) {
    const rect: DOMRect = element[0].getBoundingClientRect();
    const scrollTop: number = window.pageYOffset || document.documentElement.scrollTop;

    /* The 80 is the hight of the navigation bar */
    window.scrollTo({
      top: scrollTop + rect.top - 80,
      behavior: 'smooth'
    });
  }
}