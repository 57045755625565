import './Projects.scss';
import {
  ballShadow,
  influencerMarketingMain,
  paymentSystemMain,
  wishfulMain,
} from 'assets';
import appRoutes from 'routes';
import Card from 'common/components/Card/Card';

const Projects = () => {
  return (
    <div className='project-wrapper'>
      <img className='ball first-ball' src={ballShadow} alt='' />
      <img className='ball second-ball' src={ballShadow} alt='' />
      <img className='ball third-ball' src={ballShadow} alt='' />
      <p className='project-title'>Projects Portfolio</p>
      <div className='project-wrapper-list'>
        <Card
          text='Ninja Influence is the first app for both Influencer Marketing and Influencers. Manage your campaign and track vital stats and data to increase your marketing ROI.'
          image={influencerMarketingMain}
          url={appRoutes.influencer}
          classNameTag='influencer'
        />
        <Card
          text='Wishful Market is a unique online marketplace catering to your sophisticated shopping desires. All your wishes are in one place.'
          image={wishfulMain}
          url={appRoutes.wishful}
          classNameTag='wishful'
        />
        <Card
          text='With the Ninja Influence payment system, you can pay your Influencer directly via card, PayPal, or with Wallets and the option to use Crypto.'
          image={paymentSystemMain}
          url={appRoutes.payment}
          classNameTag='payment'
        />
      </div>
    </div>
  );
};

export default Projects;
