import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { UseWindowSize, UseWindowScroll } from 'common/hooks/UseWindowSize';
import appRoutes from 'routes';
import Contact from 'common/components/Contact/Contact';
import Home from 'screens/home/Home';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { lazy, Suspense } from 'react';

const InfluencerMarketing = lazy(
  () => import('screens/influencerMarketing/InfluencerMarketing')
);
const PaymentSystem = lazy(() => import('screens/paymentSystem/PaymentSystem'));
const Wishful = lazy(() => import('screens/wishful/Wishful'));

const App = () => {
  const [, screenWidth] = UseWindowSize();
  const scroll = UseWindowScroll();
  const [isButtonInTheViewport, setIsButtonInTheViewport] = useState(false);
  let rect;

  useEffect(() => {
    if (screenWidth > 700) {
      const element = document.getElementsByClassName('kk');
      rect = element[0]?.getBoundingClientRect();

      if (rect) {
        if (rect.bottom >= 0) {
          if (!isButtonInTheViewport === true) setIsButtonInTheViewport(true);
        } else {
          if (!isButtonInTheViewport === false) setIsButtonInTheViewport(false);
        }
      }
    }
  }, [scroll, screenWidth]);

  return (
    <BrowserRouter>
      <NavigationBar isButtonInTheViewport={isButtonInTheViewport} />
      <Suspense fallback={<p>Loading...</p>}>
        <Routes>
          <Route
            path={appRoutes.influencer}
            element={<InfluencerMarketing />}
          />
          <Route path={appRoutes.payment} element={<PaymentSystem />} />
          <Route path={appRoutes.wishful} element={<Wishful />} />
          <Route path={appRoutes.home} element={<Home />} />
          {/* Default page */}
          <Route path='/' element={<Navigate replace to={appRoutes.home} />} />
          {/* 404 Route to home page */}
          <Route path='*' element={<Navigate replace to={appRoutes.home} />} />
        </Routes>
      </Suspense>
      <Contact />
    </BrowserRouter>
  );
};

export default App;
