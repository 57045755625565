import './Contact.scss';
import { call, linkedin, email, looshesLogo, whatsApp } from 'assets';

const Contact = () => {
  return (
    <div className='contact-wrapper'>
      <div className='contact-info'>
        <p className='contact-title'>Contact</p>

        <div className='op'>
          <a href='https://wa.link/7citj4' className='info-item'>
            <div className='image-wrapper'>
              <img src={whatsApp} alt='whatsApp icon' />
            </div>
            <p className='contact-description'>WhatsApp</p>
          </a>
          <a href='tel:+972 50-5646-142' className='info-item'>
            <div className='image-wrapper'>
              <img src={call} alt='phone icon' />
            </div>
            <p>+972 50-5646-142</p>
          </a>

          <a
            href='mailto:maorknafoux@gmail.com'
            className='info-item'
            title='Share by Email'
          >
            <div className='image-wrapper'>
              <img src={email} alt='phone icon' />
            </div>
            <p>maorknafoux@gmail.com</p>
          </a>

          <a
            href='https://www.linkedin.com/in/maor-knafo-1927b4166/'
            target='_blank'
            className='info-item'
            rel='noreferrer'
          >
            <div className='image-wrapper'>
              <img src={linkedin} alt='phone icon' />
            </div>
            <p>https://www.linkedin.com/in/maor-knafo-1927b4166/</p>
          </a>
        </div>
      </div>

      <a className='looshes' href='https://looshes.com/'>
        Looshes נבנה על ידי
        <img
          src={looshesLogo}
          alt="The company's logo that built this website."
        />
      </a>
    </div>
  );
};

export default Contact;
