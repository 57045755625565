import './Hero.scss';
import { ai, ballShadow, css, figma, hand, maor, ps, xd } from 'assets';
import { scrollToSection } from 'common/utils';
import { UseWindowSize } from 'common/hooks/UseWindowSize';
import Button from 'common/components/Button/Button';

const Hero = () => {
  const [, screenWidth] = UseWindowSize();
  const screenLimit = 699;

  return (
    <div className='hero-wrapper'>
      <div className='hero-wrapper-right-side'>
        <div className='shadow-wrapper'>
          <div className='over'></div>
          <img className='maor-image' src={maor} alt='' />
        </div>
        <img className='figma' src={figma} alt='Figma icon' />
        <img className='xd' src={xd} alt='Adobe XD icon' />
        <img className='css' src={css} alt='CSS icon' />
        <img className='ai' src={ai} alt='Adobe Illustrator icon' />
        <img className='ps' src={ps} alt='Adobe Photoshop icon' />
        <img className='ball-shadow' src={ballShadow} alt='' />
      </div>

      <div className='about'>
        <p className='about-header'>Hello, I'm</p>
        <div className='about-div'>
          <p>MAOR </p>
          <img src={hand} alt='' />
        </div>
        <p className='about-description'>
          Welcome to the Deluxe website! I am a creative UX/UI designer with
          hands-on experience in the entire design process lifecycle—versatile,
          a quick learner, eager to dive into new technologies and grow as a
          problem solver.
        </p>
        {screenWidth > screenLimit ? (
          <Button
            text="Let's Talk"
            className='kk'
            onClick={() => scrollToSection('contact-wrapper')}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Hero;
